<template>
  <div>
    <!-- 시나리오 목록 -->
    <c-table
      ref="table"
      title="LBL0001165"
      :columns="grid.columns"
      :gridHeight="grid.height"
      :data="grid.data"
      :merge="grid.merge"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      :editable="false"
    >
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name==='customCol'">
          <component
            :is="imprComponent"
            :col="col"
            :props="props"
            :inputEditable="false"
            :requestContentsCols="requestContentsCols"
            tableKey="ramHazopAssessScenarioId"
            ibmTaskTypeCd="ITT0000001"
            ibmTaskUnderTypeCd="ITTU000001"
            @imprChange="imprChange"
          />
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'hazop-scenario-TL',
  props: {
    param: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',
        ramStepCd: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
      grid: {
        merge: [
          { index: 0, colName: 'processName' },
          { index: 1, colName: 'processDiagram' },
          { index: 2, colName: 'processDiagramNode' },
          { index: 3, colName: 'processDiagramNodeGuide' },
        ],
        columns: [
          {
            fix: true,
            name: 'processName',
            field: 'processName',
            // 공정
            label: 'LBLPROCESS',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            fix: true,
            name: 'diagramTitle',
            field: 'diagramTitle',
            description: 'diagramNo',
            // 도면
            label: 'LBL0001001',
            align: 'center',
            style: 'width:130px',
            sortable: false,
          },
          {
            fix: true,
            name: 'nodeTitle',
            field: 'nodeTitle',
            description: 'nodeNo',
            // 노드
            label: 'LBL0001004',
            align: 'center',
            style: 'width:130px',
            sortable: false,
          },
          {
            fix: true,
            name: 'deviationName',
            field: 'deviationName',
            // 이탈
            label: 'LBL0001005',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            fix: true,
            name: 'nodeVariableDesignIntent',
            field: 'nodeVariableDesignIntent',
            // 설계의도
            label: 'LBL0001015',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'riskOccurrenceCause',
            field: 'riskOccurrenceCause',
            // 원인
            label: 'LBL0001006',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'riskOccurrenceResult',
            field: 'riskOccurrenceResult',
            // 결과
            label: 'LBL0001007',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'relationLaw',
            field: 'relationLaw',
            // 관련법규
            label: 'LBL0001021',
            align: 'left',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'currentSafetyMeasures',
            field: 'currentSafetyMeasures',
            // 현재안전조치
            label: 'LBL0001025',
            align: 'left',
            style: 'width:170px',
            sortable: false,
          },
          {
            name: 'before',
            field: 'before',
            // 개선전
            label: 'LBL0001008',
            align: 'center',
            child: [
              {
                name: 'beforeFrequency',
                field: 'beforeFrequency',
                // 빈도
                label: 'LBL0001009',
                align: 'center',
                style: 'width:40px',
                sortable: false
              },
              {
                name: 'beforeStrength',
                field: 'beforeStrength',
                // 강도
                label: 'LBL0001010',
                align: 'center',
                style: 'width:40px',
                sortable: false
              },
              {
                name: 'beforeRisk',
                field: 'beforeRisk',
                // 위험도
                label: 'LBL0001011',
                align: 'center',
                style: 'width:70px',
                sortable: false,
              },
            ]
          },
          {
            name: 'after',
            field: 'after',
            // 개선후
            label: 'LBL0001012',
            align: 'center',
            child: [
              {
                name: 'afterFrequency',
                field: 'afterFrequency',
                // 빈도
                label: 'LBL0001009',
                align: 'center',
                style: 'width:40px',
                sortable: false
              },
              {
                name: 'afterStrength',
                field: 'afterStrength',
                // 강도
                label: 'LBL0001010',
                align: 'center',
                style: 'width:40px',
                sortable: false
              },
              {
                name: 'afterRisk',
                field: 'afterRisk',
                // 위험도
                label: 'LBL0001011',
                align: 'center',
                style: 'width:70px',
                sortable: false,
              },
            ]
          },
          {
            name: 'assessDate',
            field: 'assessDate',
            // 평가일
            label: 'LBL0001013',
            align: 'center',
            style: 'width:90px',
            sortable: false,
          },
          {
            name: 'assessUserName',
            field: 'assessUserName',
            // 평가자
            label: 'LBL0001014',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'riskbookFlag',
            field: 'riskbookFlag',
            // 위험등록부
            label: 'LBL0001171',
            align: 'center',
            style: 'width:90px',
            sortable: true,
          },
          {
            name: 'impr',
            field: 'impr',
            // 개선진행
            label: 'LBL0001178',
            align: 'center',
            child: [
              {
                name: 'customCol',
                field: 'customCol',
                label: 'LBLIMPRNO_PROGRESS',
                align: 'center',
                style: 'width:150px',
                type: 'custom',
                sortable: false
              },
              {
                name: 'improvementMeasures',
                field: 'improvementMeasures',
                // 개선대책
                label: 'LBL0001408',
                align: 'left',
                style: 'width:200px',
                sortable: false
              },
            ]
          },
        ],
        data: [],
        height: '600px'
      },
      imprComponent: () => import(`${'@/pages/common/ibm/tableImpr.vue'}`),
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
    requestContentsCols() {
      return ['deviationName', 'riskOccurrenceCause'];
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      this.listUrl = selectConfig.ram.hazop.scenario.list.url
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        ramRiskAssessmentPlanId: this.param.ramRiskAssessmentPlanId,
      }
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    imprChange() {
      this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
    },
    linkClick(data, row) {
      this.requestImprRow = row
      this.popupOptions.title = 'LBLIMPROVE';
      this.popupOptions.param = {
        sopImprovementId: data.sopImprovementId,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeImprPopup;
    },
    closeImprPopup(result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (result && result.col1) {
        if (result.col1.ibmStepCd === 'IS00000010' || result.col1.ibmStepCd === 'IS00000015' || result.col1.ibmStepCd === 'IS00000020') {
          this.requestImprRow.riskRegisterFlag = true
        }
        let splitId = this.$_.split(this.requestImprRow.sopImprovementIds, ',');
        let splitStep = this.$_.split(this.requestImprRow.ibmStepNames, ',');
        if (splitId && splitId.length > 0) {
          let idIndex = this.$_.indexOf(splitId, result.col1.sopImprovementId);
          splitStep[idIndex] = result.col1.ibmStepName;
          this.requestImprRow.ibmStepNames = splitStep.toString();
        }
        this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
      }
    },
  }
};
</script>
